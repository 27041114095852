<template>
  <q-dialog v-model="isShow" persistent>
    <q-card class="delete">
      <q-btn icon="close" @click="closeModal" flat round dense v-close-popup />

      <q-card-section>
        <div class="title">{{ content.title }}</div>
      </q-card-section>

      <q-card-section>
        <p class="description" v-for="(item, i) in content.text" :key="i">
          {{ item }}
        </p>
      </q-card-section>

      <q-card-section class="bottom-buttons">
        <q-btn label="Retour" class="btn-cancel" @click="closeModal" />

        <q-btn :label="content.labelDelete || 'Supprimer'" class="btn-trash" @click="remove">
          <q-icon class="trash-icon" />
        </q-btn>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    remove() {
      this.$emit('removed', this.content.id || '')
    }
  }
}
</script>
